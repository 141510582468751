import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline";
import useFormat from "@lib/hooks/use-format";
import React from "react";

type Props = {
  rate: number;
  isIncreasing: boolean;
  hideRate?: boolean;
  size?: "small" | "default";
  className?: string;
};

const RateDisplay = ({
  rate,
  isIncreasing,
  hideRate,
  size = "default",
  className,
}: Props) => {
  const { formatNumber } = useFormat();
  return (
    <div
      className={`flex justify-center rounded-md p-1 ${
        size === "default" ? "text-sm" : "text-xs"
      } flex-row ${
        Math.abs(rate) >= 0.005
          ? isIncreasing
            ? "text-textIncrease"
            : "text-textDecrease"
          : "text-gray-300"
      } items-center ${
        Math.abs(rate) >= 0.005
          ? isIncreasing
            ? "bg-opaqueGreen dark:bg-opaqueGreenDark"
            : "bg-opaqueRed dark:bg-opaqueRedDark"
          : "bg-gray-200 dark:bg-gray-700"
      } ${className}`}
    >
      <div>
        {Math.abs(rate) >= 0.005 ? (
          rate > 0 ? (
            <ArrowUpIcon
              className={size === "default" ? "h-4 w-4" : "h-3 w-3"}
            />
          ) : (
            <ArrowDownIcon
              className={size === "default" ? "h-4 w-4" : "h-3 w-3"}
            />
          )
        ) : null}
      </div>
      <div>{hideRate ? "- %" : `${formatNumber(Math.abs(rate), 2)}%`}</div>
    </div>
  );
};

export default RateDisplay;
